import { useEffect, useState } from 'react'

const useHasScrolled = () => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    if (scrolled) return
    const onScroll = () => setScrolled(true)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [scrolled])

  return scrolled
}

export default useHasScrolled
