import { SEARCH_PAGE_CITY_MAP, SearchPageCity } from 'src/constants/searchPages'

export const MOCK_CITIES = [
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Cdmx].cityName,
    lat: 19.42847,
    lng: -99.12766,
    city: SearchPageCity.Cdmx
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Monterrey].cityName,
    lat: 25.68,
    lng: -100.31,
    city: SearchPageCity.Monterrey
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Guadalajara].cityName,
    lat: 20.66682,
    lng: -103.39182,
    city: SearchPageCity.Guadalajara
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Puebla].cityName,
    lat: 19.03793,
    lng: -98.20346,
    city: SearchPageCity.Puebla
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Merida].cityName,
    lat: 20.97537,
    lng: -89.61696,
    city: SearchPageCity.Merida
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Tijuana].cityName,
    lat: 32.5027,
    lng: -117.00371,
    city: SearchPageCity.Tijuana
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Cancun].cityName,
    lat: 21.17429,
    lng: -86.84656,
    city: SearchPageCity.Cancun
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.CiudadDeMexico].cityName,
    lat: 19.28786,
    lng: -99.65324,
    city: SearchPageCity.Toluca
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Queretaro].cityName,
    lat: 20.58806,
    lng: -100.38806,
    city: SearchPageCity.Queretaro
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Chihuahua].cityName,
    lat: 28.63528,
    lng: -106.08889,
    city: SearchPageCity.Chihuahua
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Aguascalientes].cityName,
    lat: 21.88234,
    lng: -102.28259,
    city: SearchPageCity.Aguascalientes
  },
  {
    search: SEARCH_PAGE_CITY_MAP[SearchPageCity.Leon].cityName,
    lat: 21.12908,
    lng: -101.67374,
    city: SearchPageCity.Leon
  }
]
