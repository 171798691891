import styled from '@emotion/styled'
import Link from 'next/link'
import { Colors, FontWeight } from '../../constants/theme'

const StyledDivider = styled.span`
  margin-left: 8px;
`

const StyledLocationName = styled.p<{ closest: number }>`
  text-align: start;
  color: ${Colors.primary};
  font-weight: ${FontWeight.bold};
  text-transform: uppercase;
  opacity: ${({ closest }) => (closest ? 1 : 0.35)};
  font-size: 15px;
  line-height: 12px;
  margin: 7px 0px;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    opacity: 1;
    cursor: pointer;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0px;
  }
`

interface LocationItemProps {
  name: string
  link: string
  closest: boolean
  lastCity: boolean
}

const LocationItem = ({ name, link, closest, lastCity }: LocationItemProps) => (
  <Link href={link} prefetch={false}>
    <StyledLocationName closest={closest ? 1 : 0}>
      {name} {!lastCity && <StyledDivider> | </StyledDivider>}
    </StyledLocationName>
  </Link>
)

export default LocationItem
