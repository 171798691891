import React, { createContext, useContext, useMemo, useState } from 'react'
import { DEFAULT_CENTER, DEFAULT_ZOOM } from '../constants'
import { ICoordinates } from '../types/graphql'
import { IBounds, IQueryParams } from '../types/types'

type IGeolocationContext = {
  center: ICoordinates | undefined
  setCenter: (center: ICoordinates) => void

  zoom: number | undefined
  setZoom: (zoom: number) => void

  bounds: IBounds | undefined
  setBounds: (bounds: IBounds) => void
}

export const GeolocationContext = createContext<IGeolocationContext>({
  center: DEFAULT_CENTER,
  setCenter: () => {},

  zoom: DEFAULT_ZOOM,
  setZoom: () => {},

  bounds: undefined,
  setBounds: () => {}
})

export const GeolocationProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const value = useGeolocation()

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  )
}

export const useGeolocationProvider = (): IGeolocationContext =>
  useContext(GeolocationContext)

/**
 * Hook
 */

export const useGeolocation = (filters?: IQueryParams): IGeolocationContext => {
  const [center, setCenter] = useState<ICoordinates>(
    filters?.lat && filters?.lng
      ? { lat: filters?.lat, lng: filters?.lng }
      : DEFAULT_CENTER
  )
  const [zoom, setZoom] = useState<number>(filters?.zoom ?? DEFAULT_ZOOM)
  const [bounds, setBounds] = useState<IBounds>()

  const value = useMemo(
    () => ({
      center,
      setCenter,
      zoom,
      setZoom,
      bounds,
      setBounds
    }),
    [center, zoom, bounds]
  )

  return value
}
