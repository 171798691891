import React from 'react'
import { SearchPage } from 'src/constants/searchPages'
import CityItem from './CityItem'
import { MOCK_CITIES } from './constants'

type ICitiesSectionProps = {
  canStoreVehicle?: boolean
  closestCity: string
}

function LocationsSection({
  canStoreVehicle,
  closestCity
}: ICitiesSectionProps) {
  const canStoreVehicleFilter =
    canStoreVehicle === undefined ? '' : `&canStoreVehicle=${canStoreVehicle}`

  return (
    <div className="relative flex w-full flex-wrap items-center justify-center gap-x-2 md:items-end md:justify-end md:gap-[10px] md-lg:items-center md-lg:justify-center">
      {MOCK_CITIES.map(({ search, city }, index) => (
        <CityItem
          key={search}
          name={search}
          closest={search === closestCity}
          lastCity={index === MOCK_CITIES.length - 1}
          link={`/${
            canStoreVehicleFilter
              ? SearchPage.Estacionamiento1
              : SearchPage.Bodega1
          }/${city}`}
        />
      ))}
    </div>
  )
}

export default React.memo(LocationsSection)
